import { loadStripe } from "@stripe/stripe-js";

// eslint-disable-next-line no-use-before-define, no-undef
let stripe = null;
let elements = null;
let card = null;

export default {
  data() {
    return {
      paymentErrors: null,
      justPaidForProgram: false,
      justPaidForSubscription: false,
    };
  },

  computed: {
    billingDetails() {
      console.warn(
        "You should implement billingDetails computed property on this component"
      );

      return {};
    },
  },

  methods: {
    async mountCard(element) {
      if (!stripe)
        stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

      if (!elements) {
        elements = stripe.elements();
      }

      if (card) {
        card.destroy();
      }

      card = elements.create("card");
      card.on("change", this.handleCardEvents);

      card.mount(element);
    },

    handleCardEvents(event) {
      this.paymentErrors = null;

      if (event.error) {
        this.paymentErrors = event.error.message;
      }
    },

    getGatewayPlanId(subscriptionPlan) {
      let gateway = "stripe";

      if (!subscriptionPlan) throw new Error("No plan has been selected");

      let gatewayPlan = subscriptionPlan.gateway_plans.find(
        gatewayPlan => gatewayPlan.gateway === gateway
      );

      if (!gatewayPlan)
        throw new Error(
          "The selected plan is not valid for the payment gateway"
        );

      return gatewayPlan.plan_id;
    },

    async getSetupIntent() {
      let { data: setupIntent } = await this.axios.post("/stripe/setup-intent");

      return setupIntent;
    },

    async payForProgram(paymentMethodId) {
      let purchaseResult = await this.axios
        .post(`/programs/${this.program.id}/purchase`, {
          payment_method_id: paymentMethodId,
        })
        .then(response => response.data);

      let paymentIntent = purchaseResult.paymentIntent;

      let updatedPaymentIntent = await this.takeActionsBasedOnIntentStatus(
        paymentIntent,
        paymentMethodId
      );

      await this.confirmProgramPayment(updatedPaymentIntent.id);
    },

    async confirmProgramPayment(paymentIntentId) {
      this.justPaidForProgram = true;
      try {
        await this.axios.post(`/programs/${this.program.id}/confirm-purchase`, {
          payment_identifier: paymentIntentId,
        });
      } catch (e) {
        // in case this fails, the payment can still be confirmed through webhooks
        undefined;
      }
    },

    async subscribeToPlan(priceId, paymentMethodId, promocode) {
      let subscriptionResult = await this.axios
        .post("/subscriptions", {
          plan_id: priceId,
          payment_method_id: paymentMethodId,
          promocode: promocode,
        })
        .then(response => response.data);

      let subscription = subscriptionResult.subscription;

      if (
        subscription.status === "incomplete" ||
        subscription.status === "past_due"
      ) {
        await this.takeActionsBasedOnIntentStatus(
          subscription.latest_invoice.payment_intent,
          paymentMethodId
        );
      }

      await this.confirmSubscription(subscription.id);
    },

    async confirmSubscription(subscriptionId) {
      this.justPaidForSubscription = true;

      try {
        await this.axios.post("/subscriptions/confirm", {
          subscription_id: subscriptionId,
        });
      } catch (e) {
        // in case this fails, the subscription can still be confirmed through webhooks
        undefined;
      }
    },

    async takeActionsBasedOnIntentStatus(paymentIntent, paymentMethodId) {
      if (paymentIntent.status === "succeeded") {
        return paymentIntent;
      }

      if (
        paymentIntent.status === "requires_action" ||
        (paymentIntent.last_payment_error &&
          paymentIntent.last_payment_error.decline_code ===
            "authentication_required")
      ) {
        let confirmedIntentResult = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: paymentMethodId,
          }
        );

        if (confirmedIntentResult.error) throw confirmedIntentResult.error;

        return this.takeActionsBasedOnIntentStatus(
          confirmedIntentResult.paymentIntent,
          paymentMethodId
        );
      }

      if (paymentIntent.status === "requires_payment_method")
        throw paymentIntent.last_payment_error;
    },

    async confirmCardSetup(setupIntent) {
      let result = await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: card,
          billing_details: this.billingDetails,
        },
      });

      if (result.error) throw result.error;

      return result.setupIntent;
    },
  },
};
