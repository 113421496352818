<template>
  <section class="section section-skew black-gradient-header" v-if="program">
    <video-player
      v-if="program && videoOptions && playBack"
      :options="videoOptions"
      :program="program"
    />

    <div class="background-detail mb-md-50 mb-md-0">
      <img
        v-lazy="program.img_header + '?width=1600'"
        class="img-fluid position-absolute send-back topleft"
        :alt="program.title"
      />
    </div>
    <div
      class="container-fluid container-lg py-5 shadow position-relative bring-to-front-5"
    >
      <div class="row">
        <div v-if="!program" class="col-md-8">
          <div class="row">
            <div class="col-12 text-center">Loading...</div>
          </div>
        </div>

        <div v-else class="col-12">
          <div class="row position-relative">
            <div class="col-md-6">
              <img
                class="mw-100 br-5 rounded border-white"
                v-bind:src="program.img_poster + '?width=480'"
                :alt="program.title"
              />
            </div>
            <div class="col-md-6">
              <h4 class="p-0 text-white">
                <strong v-html="program.title"></strong>
              </h4>

              <div v-for="(sensei, index) in program.presenters" :key="index">
                <div>
                  <router-link :to="`/profile/${sensei.id}`">
                    <p
                      class="text-primary small bold m-0 mt-2"
                      v-html="sensei.full_name"
                    ></p>
                  </router-link>
                </div>
              </div>

              <div
                class="small text-white drop-shadow"
                v-html="program.summary"
              ></div>

              <!-- later, we can add an skeleton or something while this data is being loaded -->
              <div class="row" v-if="programLoaded">
                <span
                  v-if="futureShow"
                  size="sm"
                  class="m-3 btn btn-tooltip btn-danger btn-sm cursor-none"
                  >Available on
                  {{ program.publication_date | moment("MMMM Do") }}</span
                >

                <div class="col-12 mt-md-4 mb-md-4">
                  <template v-if="hasAccessToContent">
                    <template v-if="programTrailer">
                      <button
                        type="button"
                        @click="playVideo(program, program.media[0])"
                        class="btn btn-icon btn-outline-play btn-icon btn-big my-2 col-sm-12 col-md-4 offset-md-2 mt-md-4 mb-md-3"
                      >
                        <span class="btn-inner--icon">
                          <i class="fa fa-play"></i>
                        </span>
                        <span class="btn-inner--text">Play</span>
                      </button>
                      <button
                        v-if="programTrailer"
                        type="button"
                        @click="playVideoTrailer(program)"
                        class="btn btn-icon btn-outline-play btn-icon btn-big col-sm-12 col-md-4 my-2 mt-md-4 mb-md-3"
                      >
                        <span class="btn-inner--icon">
                          <i class="fa fa-play"></i>
                        </span>
                        <span class="btn-inner--text">Trailer</span>
                      </button>
                    </template>
                    <template v-else>
                      <button
                        type="button"
                        @click="playVideo(program, program.media[0])"
                        class="btn btn-icon btn-outline-play btn-icon btn-big my-2 col-sm-12 col-md-4 offset-md-4 mt-md-4 mb-md-3"
                      >
                        <span class="btn-inner--icon">
                          <i class="fa fa-play"></i>
                        </span>
                        <span class="btn-inner--text">Play</span>
                      </button>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="programTrailer">
                      <button
                        v-if="programTrailer"
                        type="button"
                        @click="playVideoTrailer(program)"
                        class="btn btn-icon btn-outline-play btn-icon btn-big my-2 offset-md-2"
                      >
                        <span class="btn-inner--icon">
                          <i class="fa fa-play"></i>
                        </span>
                        <span class="btn-inner--text">Play Trailer</span>
                      </button>
                      <base-button
                        v-if="!paidForSubscription"
                        tag="router-link"
                        :to="linkToPayment"
                        type="button"
                        class="btn btn-icon btn-play btn-icon btn-big my-2"
                        id="subscribe-watch"
                      >
                        <span class="btn-inner--text"> Watch Now </span>
                      </base-button>
                    </template>
                    <template v-else>
                      <base-button
                        v-if="!paidForSubscription"
                        tag="router-link"
                        :to="linkToPayment"
                        type="button"
                        class="btn btn-icon btn-play btn-icon btn-big my-2 offset-md-4"
                        id="subscribe-watch"
                      >
                        <span class="btn-inner--text"> Watch Now </span>
                      </base-button>
                    </template>
                  </template>
                </div>
              </div>
              <div class="row small text-white justify-content-center">
                <span>Share this program on:</span>
              </div>
              <div id="social-sharing" class="row justify-content-center">
                <ShareNetwork
                  v-for="network in networks"
                  :network="network.network"
                  :key="network.network"
                  :style="{ backgroundColor: network.color }"
                  :url="currentUrl"
                  :title="program.title"
                  :description="program.description"
                  :quote="shareCopy"
                  :hashtags="sharing.hashtags"
                  :twitterUser="sharing.twitterUser"
                  :media="program.img_header"
                >
                  <div :class="network.icon"></div>
                </ShareNetwork>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-5">
        <div
          class="small text-white drop-shadow pre-wrap"
          v-html="program.description"
        ></div>
      </div>

      <div class="py-5" v-if="program.format === 'series'">
        <h5 class="p-0">
          <strong class="text-white">Episodes:</strong>
        </h5>
        <div class="row row-grid">
          <div
            class="col-md-4 col-lg-3"
            v-for="(media, index) in program.media"
            :key="index"
            v-lazy-container
          >
            <button
              v-if="hasAccessToContent"
              id="play_button"
              class="br-5 btn border-0 p-0"
              @click="playVideo(program, media)"
            >
              <i class="fa fa-play p-2 text-white"></i>
              <img
                :data-src="media.img_poster + '?width=320'"
                :data-loading="require('@/assets/img/poster_placeholder.jpg')"
                :data-error="require('@/assets/img/poster_placeholder.jpg')"
                class="mw-100 rounded"
                :alt="program.title"
              />
            </button>

            <template v-else>
              <button class="br-5 btn border-0 p-0">
                <img
                  :data-src="media.img_poster + '?width=320'"
                  :data-loading="require('@/assets/img/poster_placeholder.jpg')"
                  :data-error="require('@/assets/img/poster_placeholder.jpg')"
                  class="mw-100 rounded"
                  :alt="program.title"
                />
              </button>
            </template>
            <p class="small bold lh-140 text-white">{{ media.title }}</p>
          </div>
        </div>
      </div>
      <template v-if="program">
        <div class="py-5">
          <h5 class="p-0" v-if="authorPrograms.length > 0">
            <strong class="text-white">Other videos from this author:</strong>
          </h5>
          <div class="row row-grid">
            <div
              class="col-md-4 col-lg-3"
              v-for="(program, index) in authorPrograms"
              :key="'a-p' + index"
            >
              <router-link
                :to="{ name: 'media-detail', params: { slug: program.slug } }"
                v-lazy-container
              >
                <img
                  :data-src="
                    program.img_poster
                      ? program.img_poster + '?width=320'
                      : require('@/assets/img/poster_placeholder.jpg')
                  "
                  :data-loading="require('@/assets/img/poster_placeholder.jpg')"
                  :data-error="require('@/assets/img/poster_placeholder.jpg')"
                  class="mw-100 br-5 rounded"
                  :alt="program.title"
                />
              </router-link>
              <router-link
                :to="{ name: 'media-detail', params: { slug: program.slug } }"
              >
                <p class="small bold lh-140 text-white">
                  {{ program.title }} <br />
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="relatedPrograms" class="py-5">
          <h5 class="p-0" v-if="relatedPrograms.length > 0">
            <strong class="text-white">Other videos you might like:</strong>
          </h5>
          <div class="row row-grid">
            <div
              class="col-md-4 col-lg-3"
              v-for="(program, index) in relatedPrograms"
              :key="index"
            >
              <router-link
                :to="{ name: 'media-detail', params: { slug: program.slug } }"
                v-lazy-container
              >
                <img
                  :data-src="
                    program.img_poster
                      ? program.img_poster + '?width=320'
                      : require('@/assets/img/poster_placeholder.jpg')
                  "
                  :data-loading="require('@/assets/img/poster_placeholder.jpg')"
                  :data-error="require('@/assets/img/poster_placeholder.jpg')"
                  class="mw-100 br-5 rounded"
                  :alt="program.title"
                />
              </router-link>
              <router-link
                :to="{ name: 'media-detail', params: { slug: program.slug } }"
              >
                <p class="small bold lh-140 text-white mt-1">
                  {{ program.title }} <br />
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";

import handlesStripePayments from "@/mixins/handlesStripePayments";
import handlesUserAccess from "@/mixins/handlesUserAccess";
import handlesApiErrors from "@/mixins/handlesApiErrors";
import handlesProgram from "@/mixins/handlesProgram";

import Dinero from "dinero.js";
import get from "lodash/get";
import filter from "lodash/filter";
import sampleSize from "lodash/sampleSize";

const VideoPlayer = () => import("@/views/components/VideoPlayer.vue");

export default {
  name: "programDetail",
  metaInfo() {
    var title = "";
    if (this.program) {
      title = this.program.title;
    }
    const structuredData = {
      "@context": "http://schema.org",
      "@type": "VideoObject",
      name: this.program.title,
      description: this.program.description,
      uploadDate: "20201201T211301Z",
      thumbnailUrl: [this.program.img_poster],
      contentUrl: this.program.media[0].hls,
      isAccessibleForFree: "False",
      author: {
        "@type": "Person",
        name: this.program.presenters[0].full_name,
        image: this.program.presenters[0].img_avatar,
        description: this.program.presenters[0].description,
        nationality: this.program.presenters[0].country,
      },
    };
    return {
      script: [
        {
          type: "application/ld+json",
          json: structuredData,
        },
      ],
      title: title,
      link: [
        {
          rel: "canonical",
          href: "https://bujin.tv/media/" + this.program.slug,
        },
      ],
    };
  },
  mixins: [
    handlesStripePayments,
    handlesUserAccess,
    handlesApiErrors,
    handlesProgram,
  ],

  props: {
    programId: {
      type: Number,
      required: false,
    },
    slug: {
      type: String,
      required: false,
    },
  },

  directives: {
    BPopover: VBPopover,
    VBTooltip,
  },

  components: {
    VideoPlayer,
    //, ProgramHighlights,
    // SelectPriceButton,
  },

  data() {
    return {
      programLoaded: false,

      registrationData: {
        name: "",
        email: "",
      },

      subscriptionPlans: [],
      selectedSubscriptionPlan: null,
      playBack: false,
      isProcessingPayment: false,

      videoOptions: {
        autoplay: true,
        controls: true,
        fullscreen: true,
        aspectRatio: "16:9",
        playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2],
        poster: null,
        media: null,
        sources: [
          {
            src: null,
            type: "application/vnd.apple.mpegurl",
          },
        ],
      },
      sharing: {
        hashtags: "bujin,bujintv,karate,karatedo,wkf",
        twitterUser: "bujintv",
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "col-auto icon d-block bi bi-facebook p-2 m-2",
          // color: "#1877f2",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "col-auto icon d-block bi bi-twitter p-2 m-2",
          // color: "#1da1f2",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "col-auto icon d-block bi bi-whatsapp p-2 m-2",
          // color: "#25d366",
        },
      ],
    };
  },

  async created() {
    this.fetchSubscriptionPlans();
    await this.retrieveProgram();
    this.autoSelectBestPrice();
  },

  async beforeRouteUpdate(to, from, next) {
    await next();

    this.justPaidForProgram = false;
    this.justPaidForSubscription = false;
    this.paymentErrors = null;
    this.isProcessingPayment = false;
    this.clearApiErrors();

    await this.retrieveProgram();
    this.setUpCard();
  },
  updated() {
    this.$updateMeta.title(this.program);
  },
  mounted() {
    this.setUpCard();
  },

  computed: {
    ...mapGetters(["isAuthenticated", "paidForSubscription"]),

    ...mapState({
      program: (state) => state.currentProgram,
      programs: (state) => state.programs,
      presenters: (state) => state.presenters,
    }),

    isUserNull() {
      return this.user === null;
    },

    shareCopy() {
      return "Check, " + this.program.title + " on Bujin.tv";
    },

    currentUrl() {
      let domain = "https://bujin.tv";
      return domain + this.$route.fullPath;
    },

    futureShow() {
      if (this.program.publication_date) {
        let days = moment().diff(this.program.publication_date, "days");
        return days < 0;
      }

      return false;
    },

    programTrailer() {
      return get(this.program, "video_trailer");
    },

    hasAccessToContent() {
      if (this.program.media) {
        return this.program.media[0].hls && !this.futureShow;
      }
      return false;
    },

    authorPrograms() {
      // We are listing programs directly from the presenter object
      let author = filter(this.presenters, (presenter) =>
        presenter ? presenter.id === this.program.presenters[0].id : null
      );
      if (typeof author[0] !== "undefined") {
        let otherPrograms = filter(author[0].programs, (program) =>
          program ? program.id !== this.program.id : null
        );
        return sampleSize(otherPrograms, 4);
      }
      return [];
    },

    relatedPrograms() {
      // we should later get related programs directly from the API
      return sampleSize(
        filter(this.programs, (program) =>
          program ? program.id !== this.programId : null
        ),
        4
      );
    },

    programHighlights() {
      return get(this.program, "media[0].cuepoints", []);
    },

    showPaymentForm() {
      if (get(this.program, "is_free") && this.paidForSubscription)
        return false;
      if (this.paidForProgram(this.programId) && this.paidForSubscription)
        return false;

      return true;
    },

    showCardInput() {
      return get(this.program, "is_vod") || this.selectedSubscriptionPlan;
    },

    showProgramOnlyPriceOption() {
      if (get(this.program, "is_free") && !this.isAuthenticated) return true;
      return (
        get(this.program, "is_vod") && !this.paidForProgram(this.programId)
      );
    },

    showSubscriptionPriceOptions() {
      return !this.paidForSubscription;
    },

    canPurchase() {
      // if (this.paidForProgramAndSubscription(this.programId)) return false;

      // // can pay for subscription but has not selected plan
      // if (this.paidForProgramOnly && !this.selectedSubscriptionPlan)
      //   return false;

      if (this.isProcessingPayment) return false;

      if (this.isAuthenticated) return true;

      if (!this.registrationData.name) return false;

      if (!this.registrationData.email) return false;

      return this.isValidEmail(this.registrationData.email);
    },

    onlyRegisteringForFreeProgram() {
      return this.program.is_free && !this.selectedSubscriptionPlan;
    },

    billingDetails() {
      if (!this.user) return {};

      return {
        name: this.user.name,
        email: this.user.email,
      };
    },
    linkToPayment() {
      console.log("3PX:user null :", this.isUserNull);
      if (this.isUserNull) {
        console.log("3PX: Should go to subscribe");
        return { name: "subscribe", query: { plan: "1" } };
      } else {
        console.log("3PX: Should go to account");
        return { name: "account" };
      }
    },

    totalPrice() {
      let price = Dinero({ amount: 0 });

      if (!this.paidForSubscription && this.selectedSubscriptionPlan) {
        price = price.add(
          Dinero({
            amount: parseInt(this.selectedSubscriptionPlan.price.amount),
          })
        );
      }

      if (!this.paidForProgram(this.programId)) {
        price = price.add(
          Dinero({
            amount: parseInt(get(this.program, "price.amount", 0)),
          })
        );
      }

      return price.toFormat();
    },
  },

  methods: {
    ...mapActions(["fetchProgram", "fetchProgramBySlug", "fetchProfile"]),

    async retrieveProgram() {
      this.programLoaded = false;
      console.log("3PX:", this.slug, this.programId);
      if (
        Number.isNaN(this.programId) ||
        typeof this.programId === "undefined"
      ) {
        console.log("3PX: Fetch by Slug");
        await this.fetchProgramBySlug(this.slug);
      } else {
        console.log("3PX: Fetch by Id");
        await this.fetchProgram(this.programId);
      }
      console.log("3PX: Program Loaded");
      this.programLoaded = true;
    },

    async fetchSubscriptionPlans() {
      let { data: plans } = await this.axios("/subscription-plans").then(
        (response) => response.data
      );

      this.subscriptionPlans = plans;
    },

    playVideo(program, media) {
      //Klaviyo
      let progObj = {
        Id: program.id,
        Title: program.title,
      };
      this.klaviyoTrackPlayMovie(progObj);
      let video = get(media, "hls");
      this.videoOptions.media = media;
      this.videoOptions.poster = program.img_poster;
      this.videoOptions.sources[0].src = video;

      this.playBack = true;
    },
    playVideoTrailer(program) {
      let trailer = get(program, "video_trailer");

      this.videoOptions.poster = program.img_poster;
      this.videoOptions.sources[0].src = trailer;

      this.playBack = true;
    },
    stopVideo() {
      this.playBack = false;
    },
    autoSelectBestPrice() {
      if (!this.paidForSubscription) {
        let featuredSubscriptionPlan = this.subscriptionPlans.find(
          (plan) => plan.featured
        );

        if (featuredSubscriptionPlan)
          this.selectedSubscriptionPlan = featuredSubscriptionPlan;

        return;
      }

      this.selectedSubscriptionPlan = null;
    },

    selectPlan(subscriptionPlan) {
      this.selectedSubscriptionPlan = subscriptionPlan
        ? subscriptionPlan
        : null;
    },

    isPlanSelected(subscriptionPlan) {
      return (
        this.selectedSubscriptionPlan &&
        this.selectedSubscriptionPlan.id === subscriptionPlan.id
      );
    },

    presentersName(presenters) {
      return presenters && presenters.length > 0 ? presenters[0].full_name : "";
    },

    setUpCard() {
      if (this.showPaymentForm) {
        if (this.$refs.card) this.mountCard(this.$refs.card);
      }
    },

    async purchase() {
      if (
        this.paidForProgramAndSubscription(this.programId) ||
        this.isProcessingPayment
      )
        return;

      this.paymentErrors = null;
      this.clearApiErrors();
      this.isProcessingPayment = true;

      try {
        await this.registerUserIfNeeded();
      } catch (_) {
        this.isProcessingPayment = false;

        return;
      }

      if (this.onlyRegisteringForFreeProgram) {
        await this.fetchUpdatedData();

        this.paymentErrors = null;
        this.isProcessingPayment = false;
        this.autoSelectBestPrice();

        this.$notify({
          text: "You now have access to the program",
          type: "success",
        });

        return;
      }

      let setupIntent, confirmedSetupIntent, paymentMethodId;
      try {
        setupIntent = await this.getSetupIntent();
        confirmedSetupIntent = await this.confirmCardSetup(setupIntent);
        paymentMethodId = confirmedSetupIntent.payment_method;
      } catch (e) {
        this.displayPaymentError(e);

        this.$notify({
          text: "There was some error trying to setup your card",
          type: "error",
        });

        return;
      }

      try {
        if (
          this.program.is_vod &&
          !this.paidForProgram(this.programId) &&
          !this.justPaidForProgram
        ) {
          await this.payForProgram(paymentMethodId);

          this.$notify({
            text: "Your payment for the program has been processed",
          });
        }
      } catch (e) {
        this.displayPaymentError(e);

        this.$notify({
          text: "There was some error while trying to process the payment for the program",
          type: "error",
        });

        return;
      }

      try {
        // if we got to this point, it means that either the one-off charge for the program succeeded or it was a free program
        // now, we either continue and charge the subscription in case the user selected to
        // or just go straight to success part

        if (
          !this.paidForSubscription &&
          !this.justPaidForSubscription &&
          this.selectedSubscriptionPlan
        ) {
          await this.subscribeToPlan(
            this.getGatewayPlanId(this.selectedSubscriptionPlan),
            paymentMethodId
          );

          this.$notify({
            text: "Your payment for the subscription has been processed",
          });
        }
      } catch (e) {
        this.displayPaymentError(e);

        this.$notify({
          text: "There was some error while trying to process the payment for the subscription",
          type: "error",
        });

        return;
      }

      // wait for 5 seconds to let the backend sync the purchase(s) info
      await new Promise((resolve) => setTimeout(resolve, 5000));
      // get fresh data to give access to the user
      await this.fetchUpdatedData();

      this.$notify({
        text: "Your payment has been processed successfully",
        type: "success",
      });

      this.paymentErrors = null;
      this.isProcessingPayment = false;
      this.autoSelectBestPrice();
    },

    async registerUserIfNeeded() {
      if (this.isAuthenticated) {
        return Promise.resolve();
      }

      try {
        await this.$store.dispatch(
          "registerWitouthPassword",
          this.registrationData
        );

        this.$notify({
          text: "You have been signed up",
        });

        return;
      } catch (e) {
        this.handleApiErrors(e);

        return Promise.reject();
      }
    },

    displayPaymentError(error) {
      error =
        error.response && error.response.data ? error.response.data : error;

      this.isProcessingPayment = false;
      this.paymentErrors = error.message;
    },

    async fetchUpdatedData() {
      await Promise.all([this.retrieveProgram(), this.fetchProfile()]);
    },

    addToMyVideos() {
      if (this.isAuthenticated) {
        this.$store.commit("addToMyVideos", this.program);
      } else {
        this.$router.push({ name: "login" });
      }
    },

    isValidEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>
<style lang="scss">
#social-sharing .icon {
  color: white;
  font-size: 1.5rem;
}

#social-sharing a {
  margin: 0;
  padding: 0;
}
</style>
