export default {
  data() {
    return {
      apiError: null,
      apiValidationErrors: {},
    };
  },

  methods: {
    clearApiErrors() {
      this.apiError = false;
      this.apiValidationErrors = {};
    },

    handleApiErrors(e) {
      if (e.response.status === 422) {
        this.apiValidationErrors = e.response.data.errors;

        return;
      }

      if (e.response.data.message) {
        this.apiError = e.response.data.message;
      }
    },

    hasError(field) {
      return (
        field in this.apiValidationErrors &&
        this.apiValidationErrors[field].length
      );
    },

    errorFor(field) {
      return this.hasError(field) ? this.apiValidationErrors[field][0] : null;
    },
  },
};
